<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Cristian Darío Valencia Agudelo                                 ###### -->
<!-- ###### @date: Enero 2025                                                        ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <article class="parametrizacion">
    <header>
      <h3>Parametrización Contratos Expedientes</h3>
      <article>
        <div>
          <v-select v-model="contratoSeleccionado1" :items="contratos" item-text="nombreContrato"
            item-value="idContrato" label="Seleccionar Contrato" @change="handleStatusChange" outlined dense
            hide-details :menu-props="{ offsetY: true }"></v-select>
        </div>
        <div>
          <v-tooltip left color="success" :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="abrirDialogo2">
                <v-icon> add </v-icon>
              </v-btn>
            </template>
            <span>Agregar</span>
          </v-tooltip>
        </div>
      </article>
    </header>

    <section>
      <v-container>
        <v-data-table
  :headers="headers"
  :items="filteredItems"
  :items-per-page="20"
  class="elevation-1"
  :sort-by.sync="sortBy"
  :sort-desc.sync="sortDesc"
  must-sort
  no-data-text="No hay expedientes disponibles"
  :footer-props="{ 'items-per-page-text': 'Filas por página' }"
>
  <template v-slot:headerCell="{ header }">
    <th :class="['custom-header']" class="text-left">
      {{ header.text }}
    </th>
  </template>

  <template v-slot:item="{ item, index }">
    <tr :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
      <td>{{ item.contrato.idContrato }}</td>
      <td>{{ item.contrato.nombreContrato }}</td>
      <td>{{ item.contrato.tipoMovimiento }}</td>
      <td>
        <v-btn small @click="abrirDialogo(item)">Editar</v-btn>
      </td>
    </tr>
  </template>

  <template v-slot:top>
    <v-text-field
      v-model="search"
      label="Buscar"
      class="mx-4"
      append-icon="mdi-magnify"
    ></v-text-field>
  </template>

  <template v-slot:item.estado="{ item }">
    <v-chip :color="item.estado === 'Disponible' ? 'green' : 'red'" dark>
      {{ item.estado === 1 ? "Disponible" : "No disponible" }}
    </v-chip>
  </template>
</v-data-table>

      </v-container>
    </section>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          Parametrizar Archivos - Contrato {{ contratoSeleccionado?.nombreContrato }}
        </v-card-title>
        <v-card-text class="parametrizacion-archivos_body">
          <h3>Estado Completitud Contrato</h3>
          <h3>Agregar Nuevo Archivo</h3>
          <v-form ref="archivoForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select v-model="nuevoArchivo.tipoArchivo" :items="tipoArchivos" item-text="label"
                    item-value="value" label="Tipo de Archivo" dense :menu-props="{ offsetY: true }" />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="nuevoArchivo.observacion" label="Observación" dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select v-model="nuevoArchivo.obligatorio" :items="['Sí', 'No']" label="Obligatorio" dense
                    :menu-props="{ offsetY: true }" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row v-for="(prefijo, index) in nuevoArchivo.prefijos" :key="index">
                    <v-col cols="6">
                      <v-select v-model="prefijo.id" :items="listaPrefijos" item-text="estructuraPrefijo"
                        item-value="idPrefijo" label="Prefijo" @change="handlePrefijoChange(prefijo)" dense
                        :menu-props="{ offsetY: true }" />
                    </v-col>
                    <v-col cols="4" v-if="prefijo.id === 1">
                      <v-text-field v-model="prefijo.textoPersonalizado" label="Texto Personalizado" dense />
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon color="red" @click="eliminarPrefijo(index)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-btn color="primary" small @click="agregarPrefijo">
                    + Agregar Prefijo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="nuevoArchivo.prefijos.length > 0" cols="12" class="parametrizacion-nombre_generado">
                  <h4>Nombre del Archivo Generado:</h4>
                  <p class="font-weight-bold">{{ nombreArchivoGenerado }}.pdf</p>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="guardarNuevoArchivo">
                Guardar Nuevo Archivo
              </v-btn>
            </v-card-actions>
          </v-form>
          <v-data-table 
            v-if="contratoSeleccionado?.archivosContratoExpediente?.length > 0" 
            :headers="archivoHeaders"
            :items="contratoSeleccionado?.archivosContratoExpediente || []" 
            class="elevation-1 mb-5"
            item-value="nombreArchivoDescargar"
          >

            <!-- Slot por defecto (opcional) -->
            <template v-slot:body>
              <tr v-for="(item, index) in contratoSeleccionado?.archivosContratoExpediente" :key="index">
                <td>{{ parseTipoArchivo(item.tipoArchivo) }}</td>
                <td>{{ item.nombreArchivoDescargar }}</td>
                <td>{{ item.obligatorio ? 'Sí' : 'No' }}</td>
                <td>{{ item.observacion }}</td>
                <td>
                  <v-btn color="red" icon @click="eliminarArchivo(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="cerrarDialogo">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialog2" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          Crear Contrato con Expediente
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-select v-model="contratoSeleccionado2" :items="contratos" item-text="nombreContrato"
              item-value="idContrato" label="Seleccionar Contrato" dense outlined :menu-props="{ offsetY: true }" />
            <v-select v-model="contratoSeleccionado2" :items="contratos" item-text="nombreContrato"
              item-value="idContrato" label="Seleccionar Contrato" dense outlined :menu-props="{ offsetY: true }" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="crearContrato">
            Crear Contrato con Expediente
          </v-btn>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="cerrarModal2">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="loading" v-if="loading">
      <div class="loadingio-spinner-spinner-2by998twmg8">
        <div class="ldio-yzaezf3dcmj">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <h4>{{ loadingText }}</h4>
    </div>
  </article>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Swal from 'sweetalert2';


export default {
  name: 'Index',
  data: () => ({
    sortBy: 'idContrato',
    sortDesc: true,
    search: '',
    dialog: false,
    dialog2: false,
    headers: [
    { text: "Id Contrato", value: "contrato.idContrato", sortable: true },
      { text: "Nombre Contrato", value: "contrato.nombreContrato", sortable: true },
      { text: "Tipo Movimiento", value: "contrato.tipoMovimiento", sortable: true },
      { text: "Acciones", value: "actions", sortable: false }
    ],
    archivoHeaders: [
      { text: "Tipo de Archivo", value: "tipoArchivo" },
      { text: "Nombre del Archivo", value: "nombreArchivoDescargar" },
      { text: "Obligatorio", value: "obligatorio" },
      { text: "Observación", value: "observacion" },
      { text: "Acciones", value: "acciones", sortable: false },
    ],
    tipoArchivos: [
      { value: "ATTACHMENT", label: "Fórmula Médica" },
      { value: "INVOICE", label: "Factura" },
      { value: "HISTORY", label: "Hoja Entrega" },
      { value: "ORDER", label: "Autorización" },
      { value: "ATTENTION", label: "Comprobante" },
      { value: "ATTACHMENTMIPRES", label: "Fórmula Mipres" },
      { value: "ADRES", label: "Adres" },
      { value: "OTHER", label: "Historia Clínica" },
    ],
    tipoArchivosMap: {
      ATTACHMENT: "Fórmula Médica",
      INVOICE: "Factura",
      HISTORY: "Hoja Entrega",
      ORDER: "Autorización",
      ATTENTION: "Comprobante",
      ATTACHMENTMIPRES: "Fórmula Mipres",
      ADRES: "Adres",
      OTHER: "Historia Clínica",
    },
    nuevoArchivo: {
      tipoArchivo: "ATTACHMENT",
      nombreArchivoDescargar: "",
      prefijos: [],
      obligatorio: "Sí",
      observacion: "",
    },
    estadoCompletitudExpediente: [
      'COMPLETO',
      'RADICADO',
      'AUDITADO',
    ],
    items: [
    ],
    filtros: {
      producto: "",
      codigo: "",
      laboratorio: "",
      fecha: "",
      carta: "",
      fechaDisponibilidad: ""
    },
    empresa: 1,
    bodegaSeleccionada: 0,
    nombreBodega: "",
    addTitle: "",
    menu: false,
    menu2: false,
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    usuario: "",
    contratoSeleccionado: null,
    contratoSeleccionado1: 0,
    contratoSeleccionado2: null,
    contratos: [],
    loading: true,
    listaPrefijos: [],
    loadingText: 'Cargando...'
  }),
  mounted() {
    this.auth.roles = [];
    this.rolesKeycloak = this.auth.keycloak.realmAccess.roles;
    this.usuario = this.auth.username;
    this.empresa = this.enterprise.code;

    const vuexState = JSON.parse(localStorage.getItem('vuex'));
    if (vuexState.auth.roles.includes("Expediente_Administrador")) {
      this.isAdmin = true;
      this.addTitle = " - Administrador";
    }

    this.empresasUsuario();
    this.getContrato();
    this.getBodega();
    this.cargarPrefijos();
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    filteredItems() {
      if (!this.search) {
        return this.items;
      }
      const search = this.search.toLowerCase();
      console.log(search);
      return this.items.filter(item => {
        return (
          item.laboratorio.toLowerCase().includes(search)
        );
      });
    },
    filteredItems2() {
      if (!this.search) {
        return this.productos;
      }
      const search = this.search.toLowerCase();
      console.log(search);
      return this.productos.filter(item => {
        return (
          item.nombreProducto.toLowerCase().includes(search)
        );
      });
    },
    nombreArchivoGenerado() {
      return this.nuevoArchivo.prefijos
        .map((p) =>
          p.id === 1 ? p.textoPersonalizado : this.listaPrefijos.find((x) => x.idPrefijo === p.id)?.estructuraPrefijo
        )
        .filter(Boolean)
        .join("");
    },
  },

  methods: {
    ...mapMutations(["setEnterprise"]),

    async empresasUsuario() {
      const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))
      console.log(codEmpresas);
    },
    parseTipoArchivo(tipoArchivo) {
      return this.tipoArchivosMap[tipoArchivo] || "Desconocido";
    },
    changeView() {
      this.view = !this.view;
    },
    getBodega() {
      this.loading = true;
      this.$http.get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          console.log(data);
          this.bodegaSeleccionada = data.codigoBodega;
          this.nombreBodega = data.nombreBodega;
          this.getContratosInit();
          this.loading = true;
        })
        .catch(err => console.log(err))
    },

    async getContrato() {
      this.$http
        .get(`msa-process-file/api/contrato/listarContratos`)
        .then((result) => {
          console.log(result);
          this.contratos = result.data;
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    },

    async getContratosInit() {
      this.$http
        .get(`msa-process-file/api/contratoexpediente/listarAllContratoExpediente?idEmpresa=${this.empresa}`)
        .then((result) => {
          console.log(result);
          this.items = result.data;
          this.showSuccessMessage = true;
          this.loading = false;

        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    },

    async getContratos() {
      this.$http
        .get(`msa-process-file/api/contratoexpediente/listarOneContratoExpediente?idEmpresa=${this.empresa}&idContrato=${this.contratoSeleccionado1}`)
        .then((result) => {
          console.log(result);
          this.items = result.data;
          this.showSuccessMessage = true;
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    },
    mostrarContrato(contrato) {
      this.$http
        .get(`msa-process-file/api/contratoexpediente/listarOneContratoExpediente?idEmpresa=${this.empresa}&idContrato=${contrato.contrato.idContrato}`)
        .then((result) => {
          console.log(result.data);
          this.contratoSeleccionado = result.data[0];
          console.log(this.contratoSeleccionado)
          this.dialog = true;
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    editItem(item) {
      console.log(item);
      this.$router.push({ path: '/modules/carta/' + item.id });
    },
    seePdf(item) {
      console.log(item);
      this.$router.push({ path: '/modules/carta/' + item.id });
    },
    deleteItem(item) {
      console.log(item);
      Swal.fire({
        title: '¿Estás seguro?',
        text: "¿En realidad deseas eliminar esta registro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http
            .delete("msa-administration/api/carta/" + item.id)
            .then((result) => {
              console.log(result);
              this.items = this.items.filter((it) => it.id != item.id);
              Swal.fire(
                'Eliminado',
                'El expediente ha sido eliminada correctamente.',
                'success'
              );
            })
            .catch((error) => {
              console.log(error?.response?.data);
            });

        }
      });
    },crearContrato() {
      let data = {
        "empresa": {
          "idEmpresa": this.empresa
        },
        "contrato": {
          "idContrato": this.contratoSeleccionado2
        }
      }
      this.$http
        .post("msa-process-file/api/contratoexpediente/crearContratoExpediente", data)
        .then((result) => {
          console.log(result);
          this.getContratosInit();
          this.dialog2 = false;
          Swal.fire(
            'Contrato Expediente',
            'El contrato ha sido inicializado.',
            'success'
          );
          this.getContratos();
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    },
    abrirDialogo2() {
      this.dialog2 = true;

    },
    abrirDialogo(contrato) {
      this.mostrarContrato(contrato);

    },
    async cargarPrefijos() {
      this.$http
        .get("msa-process-file/api/prefijoexpediente/listarPrefijoExpediente")
        .then((result) => {
          console.log("listaPrefijos", result);
          this.listaPrefijos = result.data;
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    },
    agregarPrefijo() {
      this.nuevoArchivo.prefijos.push({ id: null, textoPersonalizado: "" });
    },
    eliminarPrefijo(index) {
      this.nuevoArchivo.prefijos.splice(index, 1);
    },
    handlePrefijoChange(prefijo) {
      if (prefijo.id !== 1) {
        prefijo.textoPersonalizado = "";
      }
    },
    cerrarModal2() {
      this.dialog2 = false;
    },
    cerrarDialogo() {
      this.dialog = false;
      this.reiniciarNuevoArchivo();
    },
    handleStatusChange() {
      this.getContratos();
    },
    reiniciarNuevoArchivo() {
      this.nuevoArchivo = {
        tipoArchivo: "ATTACHMENT",
        nombreArchivoDescargar: "",
        prefijos: [],
        obligatorio: "Sí",
        observacion: "",
      };
    },
    guardarNuevoArchivo() {
      const existe = this.contratoSeleccionado.archivosContratoExpediente.some(
        (archivo) => archivo.tipoArchivo === this.nuevoArchivo.tipoArchivo
      );

      if (existe) {
        Swal.fire(
          'Contrato Expediente',
          'El tipo de archivo, ya existe para este contrato.',
          'warning'
        );
        return;
      }

      let prefijoArchivoDescargar = [];
      console.log("Prefijos:", this.nuevoArchivo.prefijos);

      this.nuevoArchivo.prefijos.map((it) => {
        prefijoArchivoDescargar.push(
          {
            "prefijoExpediente": {
              "idPrefijo": it.id
            },
            "valorPrefijoPersonalizado": it.textoPersonalizado
          }
        );
      });
      delete this.nuevoArchivo.prefijos;
      let data = {
        ...this.nuevoArchivo,
        prefijoArchivoDescargar,
        "contratoExpediente": {
          "idContratoExpediente": this.contratoSeleccionado.idContratoExpediente
        }
      };
      data.obligatorio = (data.obligatorio == "Sí");
      console.log(data);
      this.$http
        .post("msa-process-file/api/contratoexpediente/crearArchivoContratoExpediente", data)
        .then((result) => {
          console.log(result);
          this.contratoSeleccionado.archivosContratoExpediente.push(result.data);
          this.reiniciarNuevoArchivo();
          Swal.fire(
            'Contrato Expediente',
            'El archivo ha sido almacenado.',
            'success'
          );
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    },
    eliminarArchivo(archivo) {
      console.log(archivo)
      Swal.fire({
        title: '¿Estás seguro?',
        text: "¿En realidad deseas eliminar este archivo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http
            .delete("msa-process-file/api/contratoexpediente/eliminarArchivoContratoExpediente/" + archivo.idArchivoContratoExpediente)
            .then((result) => {
              console.log(result);
              this.contratoSeleccionado.archivosContratoExpediente = this.contratoSeleccionado.archivosContratoExpediente.filter(
                (item) => item !== archivo
              );
              Swal.fire(
                'Eliminado',
                'El archivo ha sido eliminada correctamente.',
                'success'
              );
            })
            .catch((error) => {
              console.log(error?.response?.data);
            });

        }
      });
    },
  }
}
</script>

<style scoped>
ul,
ol {
  list-style: none;
}

.v-data-table-header {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.row-even {
  background-color: #fafafa;
}

.row-odd {
  background-color: #f5f5f5;
}

.v-chip {
  font-size: 0.875rem;
}

article.parametrizacion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

article.parametrizacion .v-data-table .v-text-field {
  display: none;
}

article.parametrizacion>header {
  margin-bottom: 1rem;
  width: 90%;
}

article.parametrizacion>header h3 {
  padding-left: 1rem;
  margin-bottom: .5rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: left;
  color: #0D47A1;
  width: 90%;
}

article.parametrizacion>header h2 {
  padding-left: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

article.parametrizacion>header>article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

article.parametrizacion>header>article div {
  margin: 0 1rem;
  width: 100%;
}

article.parametrizacion>header>article div label {
  margin-left: .5rem;
  width: 100%;
}

article.parametrizacion i {
  margin-right: 0 !important;
}

article.parametrizacion>section .container {
  width: 100%;
  max-width: 100% !important;
}

article.parametrizacion>section {
  width: 100%;
}

article.parametrizacion>section>article {
  display: flex;
  justify-content: flex-end;
  width: 90%;
}

article.parametrizacion>section>article div {
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
}

article.parametrizacion>section ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: stretch
}

.parametrizacion-archivos_body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.parametrizacion-nombre_generado p {
  color: #0d47a1;
  margin-bottom: 0;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 53px);
  background-color: rgba(0, 0, 0, .5);
  z-index: 2;
}

.loading h4 {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}

.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}

.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}
</style>